<template>
  <v-container>
    <v-row class="spacedRow">
      <h3 class="text-center">DB Backup &amp; Restore Management</h3>
    </v-row>
    <v-row class="spacedRow">
    </v-row>
    <v-row class="spacedRow">
      <v-col cols="7">
        <h4>Backup or restore criteria:</h4>
      </v-col>
      <v-col cols="2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              id="tmcLocalFrontendBulkClearCache"
              class="tmc-local-frontend-bulk-clear-cache"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              @click="clearCache"
            >
              Clear Cache
            </v-btn>
          </template>
          <span>It will clear cache of whole system.</span>
        </v-tooltip>
      </v-col>
      <v-col>(Total space consumed: {{totalSizeStr}})</v-col>
    </v-row>
    <v-row class="spacedRow">
      <v-col>
        <v-card
          rounded
          elevation="2"
        >
        <v-card-subtitle>Notes on backup groups</v-card-subtitle>
        <v-card-text>
          Different backup groups capture different aspects of the system.
          <ul>
            <li>tmc-local-backend (all program specific data,
              import/export mappings, audit records, etc)</li>
            <li>tmc-local-auth (general user administration, system configuration,
              program-specific configuration)</li>
          </ul>
        </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <div>
          <v-file-input id="tmc-bak-file-upload"
            accept=".gz"
            label="Upload your own Mongo DB backup to use (.bson.gz)"
            show-size
            clearable
            :disabled="!canUpload"
            v-model="uploadFileNameUI"
            @change="performUpload"
          />
          <span class="ml-4">(note: the system only allows 1 upload every 15 seconds)</span>
        </div>
      </v-col>
    </v-row>
    <v-row class="spacedRow">
      <v-col>
        <v-progress-linear indeterminate v-if="this.activeDbOp">
        </v-progress-linear>
        <span id="tmc-status-bar">{{activeDbOpMsg}}</span>
      </v-col>
    </v-row>
    <v-row class="spacedRow">
      <v-col>
        <v-select id="tmc-backup-group"
          :value="query.group"
          @change="val => groupChg(val)"
          :items="groups"
          :label="`By Group`"
        ></v-select>
      </v-col>
      <v-col>
        <v-combobox
          v-model="query.prefix"
          :items="prefixes"
          :rules="reqdSelectValidation"
          label="By Prefix"
          @update:search-input="prefixChg"
        >
        </v-combobox>
      </v-col>
    </v-row>
    <v-row class="spacedRow">
      <v-col>
        <v-btn id="tmc-btn-restore"
          color="primary"
          :disabled="!backupSelected || !canRestore"
          @click="performRestore"
        >
          Restore
        </v-btn>
        <v-text-field
          v-model="query.nsInclude"
          @input="nsIncludeChg"
          :disabled="!backupSelected || !canRestore"
          :label="`Collections to be restored (*, ${query.group}.*, etc)`"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-btn
          color="primary"
          :disabled="!canBackup"
          @click="performBackup"
        >
          Backup (with prefix: '{{query.prefix}}')
        </v-btn>
        <br />
        <span>(note: the system only allows 1 backup every 15 seconds)</span>
      </v-col>
    </v-row>
    <v-row class="spacedRow">
      <v-col>
        <v-list class="elevation-3">
          <v-subheader>Backups of {{query.group}}</v-subheader>
          <v-list-item-group
            v-model="restoreBakIndex"
            color="primary"
          >
            <v-list-item id="tmc-backup-list"
              v-for="(backup, i) in backups"
              :key="i"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{`${backup.file} - ${backup.sizeStr}`}}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  class="tmc-local-frontend-bulk-download-backup-btn"
                  icon
                  @click="performDownload(backup.file)">
                  <v-icon color="grey lighten-1">{{icons.mdiDownload}}</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action>
                <v-btn
                  class="tmc-local-frontend-bulk-delete-backup-btn"
                  icon
                  @click="performDelete(backup.file)">
                  <v-icon color="grey lighten-1">{{icons.mdiDelete}}</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {
  mapActions,
  mapMutations,
  mapState,
  mapGetters,
} from 'vuex';

import {
  mdiDatabaseImport,
  mdiDatabaseExport,
  mdiDownload,
  mdiDelete,
} from '@mdi/js';

import { clients } from '../../util/clients';

const { backendRest } = clients.direct;

const initdata = () => ({
  icons: {
    mdiDatabaseImport,
    mdiDatabaseExport,
    mdiDownload,
    mdiDelete,
  },
  query: {
    prefix: 'general',
    group: 'tmc-local-backend',
    nsInclude: '*',
  },
  groups: [
    'tmc-local-backend',
    'tmc-local-auth',
  ],
  prefixes: [
    'general',
    'nightly',
  ],
  timing: undefined,
  uploadFileNameUI: undefined,
  canUpload: true,
  canBackup: true,
  canRestore: true,
  restoreBakIndex: undefined,
  headers: [
    { text: 'Backups', value: 'type' },
    { text: 'Actions', value: 'actions', sortable: false },
  ],
  groupMapping: {
    'tmc-local-backend': 'OD system',
    'tmc-local-auth': 'settings/configuration',
  },
});

export default {
  name: 'BackupRestoreDb',
  data: () => initdata(),
  components: {
  },
  computed: {
    ...mapState('jobs', [
      'backups',
      'activeDbOp',
      'activeDbOpMsg',
      'totalSizeStr',
    ]),
    ...mapGetters('jobs', [
      'backupFiles',
    ]),
    backupSelected() {
      return this.restoreBakIndex !== undefined && this.restoreBakIndex !== '';
    },
    reqdSelectValidation() {
      return [
        (value) => !!value || 'Required',
      ];
    },
  },
  methods: {
    ...mapActions([
      'flashInfo',
      'flashWarn',
      'flashError',
      'reAuth',
      'flashSuccess',
    ]),
    ...mapActions('jobs', [
      'listDumps',
      'backup',
      'restore',
    ]),
    ...mapMutations('jobs', [
      'setPrefix',
      'setNsInclude',
      'setGroup',
      'setBackups',
      'setBackup',
      'setActiveDbOp',
    ]),
    groupChg(val) {
      this.query.group = val;
      this.setGroup(this.query.group);
      this.reloadBackups();
    },
    prefixChg(val) {
      this.query.prefix = val;
      this.setPrefix(val);
    },
    nsIncludeChg(val) {
      this.query.nsInclude = val;
      this.setNsInclude(val);
    },
    reloadBackups() {
      this.setBackups([[], '']); // clear for reload
      this.listDumps();
    },
    performBackup() {
      this.backup();
      this.canBackup = false;
      setTimeout(() => {
        this.canBackup = true;
      }, 15000);
    },
    performRestore() {
      if (window.confirm(`This will replace all data in the ${this.groupMapping[this.query.group]} with data from the backup dated ${this.getBackupDateTime()}. Are you sure you want to proceed?`)) {
        if (window.confirm(`Are you absolutely sure you want to overwrite the existing data in ${this.groupMapping[this.query.group]} with the data from the backup dated ${this.getBackupDateTime()}?`)) {
          this.setBackup(this.backupFiles[this.restoreBakIndex]);
          this.restore();
          this.canRestore = false;
          setTimeout(() => {
            this.canRestore = true;
          }, 15000);
        }
      }
    },
    async performUpload(file) {
      this.timing = new Date();
      if (!file || !file.size || !file.name) {
        return;
      }

      const confirmMsg = `Is file "${file.name}" for the "${this.query.group}" backup group?`;
      const addendumMsg = 'Mixing backup types can put the system in an unusable state.';
      if (!window.confirm(`${confirmMsg} ${addendumMsg}`)) {
        this.uploadFileNameUI = null; // UX purposes only - clear file name
        return;
      }
      this.setActiveDbOp({ isActive: true, msg: 'Uploading...' });
      const form = new FormData();
      form.append('group', this.query.group);
      form.append('file', file.name);
      form.append('data', file);

      const formHeaders = {
        'Content-Type': 'multipart/form-data',
      };
      const results = await backendRest.post('/jobs/dumps/upload', form, {
        headers: formHeaders,
        timeout: 240000,
      });

      if (!results || !results.data || results.data.status !== 'ok') {
        this.setActiveDbOp({ isActive: false, msg: 'Unable to upload file.' });
      } else {
        const elapsed = new Date().getTime() - this.timing.getTime();
        this.setActiveDbOp({ isActive: false, msg: `Done. Time elapsed: ${elapsed / 1000}` });
        this.flashInfo('File uploaded!');

        this.reloadBackups();
        this.uploadFileNameUI = null;
        this.canUpload = false;
        setTimeout(() => {
          this.canUpload = true;
        }, 15000);
      }
    },
    async performDownload(fileName) {
      if (!fileName) {
        this.flashError('Missing file name. Cannot download.');
        return;
      }
      const { group } = this.query;
      const { jwt } = await this.reAuth();
      const url = `${backendRest.defaults.baseURL}/jobs/dump/${group}/${fileName}?token=${jwt}`;
      window.open(url, '_blank');
    },
    async performDelete(fileName) {
      if (!fileName) {
        this.flashError('Missing file name. Cannot upload.');
        return;
      }
      const { group } = this.query;
      const results = await backendRest.delete(`/jobs/dump/${group}/${fileName}`);
      if (!results || !results.data || results.data.status !== 'ok') {
        this.flashInfo('Unable to delete file.');
      } else {
        this.flashInfo(`File "${fileName}" deleted.`);
        this.reloadBackups();
      }
    },
    getBackupDateTime() {
      const backupDetails = this.backups[this.restoreBakIndex] || {};
      let dateTime = '';
      let backupFileName = '';
      try {
        backupFileName = backupDetails.file;
        [, dateTime] = backupDetails.file.split('.')[0].split('-');
      } catch {
        console.warn(`Unable to parse file name ${backupDetails}`);
      }
      return dateTime || backupFileName;
    },
    async clearCache() {
      if (window.confirm('Are you sure to clear cache?')) {
        const group = ['ODFYDeptCache', 'CacheFundFY', 'CacheAccBalance', 'WarFundAccountBalanceDulicateEntries',
          'clearWarGlobalValues', 'clearPayrollGlobalValues', 'clearMiscGlobalValues', 'clearMtgGlobalValues'];
        let response;
        try {
          response = await backendRest.get(`cache-utility/clear-cache?group=${group}`);
        } catch (e) {
          console.error(e);
        }
        if (response && response.data && response.data.status === 'ok') {
          this.flashSuccess('Cache got successfully cleared.');
        } else {
          this.flashError('There is some issue on clearing.');
        }
      }
    },
  },
  created() {
    this.setPrefix(this.query.prefix);
    this.setNsInclude(this.query.nsInclude);
    this.setGroup(this.query.group);
    this.reloadBackups();
  },
};
</script>

<style lang="sass">
  .spacedRow
    padding-top: 1rem
</style>
